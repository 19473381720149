
.basic-underline_link,
%basic-underline_link {
  position: relative;
  outline: 0;
  text-decoration: none;
  transition: color .4s cubic-bezier(0.165, 0.840, 0.440, 1.000);

  &:hover {
    transition: color .0s;
  }

  &.link-active {
    pointer-events: none;
    text-decoration: underline;
  }

  &.link-disabled{
    pointer-events: none;
  }
}

.__cursor {
  .basic-underline_link,
  %basic-underline_link {
    &:hover {
      text-decoration: underline;
    }
  }
}

@mixin basic-underline_link($color:$black, $hover:$assertive) {
  @extend %basic-underline_link;
  color:$color;
  &.link-active {
    color: $color;
  }
  &:hover {
    color: $hover;
  }
}



