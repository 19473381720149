
.section-marcas {

  &:before {
    top:40vh !important;
    height: calc(100% - 40vh - 50px) !important;
  }

  .title-marco {
    &:after {
      display: none !important;
    }
  }

  .arrow-holder {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-around;

    .arrow-down {
      height: 60px;
    }
  }
}

@media (max-width: $tabletPortrait) {
  .section-marcas {

    .title-marco {
      margin-top: 3em !important;
    }


    &:before {
      height: calc(100% - 15vh - 50px) !important;
      top: 15vh !important;
    }
  }
}