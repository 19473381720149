.progress-bar-holder{
  position: fixed;
  @include z-index($z-index-progress);
  top:0px;
  left:0px;
  width: 6px;
  height: 100%;
  background: $transparent;
}
.progress-bar {
  position: absolute;
  top:0px;
  left:0px;
  background-color: $assertive;
  width: 6px;
  height: 0;
}

.load-bar {
  position: fixed;
  @include z-index($z-index-progress);
  top:0px;
  left:0px;
  width: 0px;
  height: 8px;
  overflow: hidden;
}

.load-bar__bar{
  position: absolute;
  top:0;
  left:0;
  width: 100vw;
  height: 8px;
  background: $assertive;
}



