
._u,
.__u {
  .__l_effect {
    position: relative;
    display: inline;
    &:before {
      position: absolute;
      display: block;
      content: '';

      bottom: -5px;
      left: 0px;
      height: 10px;
      background-color: $black;
      width: 0%;

      @include animation-name(underline-out);
      @include animation-duration(1s);
      @include animation-fill-mode(forwards);
      @include animation-timing-function(cubic-bezier(0.190, 1.000, 0.220, 1.000));
    }
  }
}

a:hover,
.__hover {
  .__l_effect {
    &:before {
      @include animation-name(underline-in);
      @include animation-duration(.3s);
      @include animation-fill-mode(forwards);
      @include animation-timing-function(cubic-bezier(0.250, 0.460, 0.450, 0.940));
    }
  }
}