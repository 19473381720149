.main-footer {
  position: relative;
  padding-top: 100px;
  padding-bottom: 20px;
  background: $assertive;

  margin-top: 100px;

  @include z-index($z-index-footer);

  .title-marco {
    color: $white;
    font-size: 48px;
    span {
      border-color: $white !important;
    }
  }
}

.main-footer__logo {
  position: absolute;
  @include z-index(1);
  @include transform-3D(50%, 0, 1px);
  right: 0;
  top:10%;
  height: 70%;
  width: auto;
  fill: $transparent;
  stroke-width: 1px;
  stroke: $white;
}

.main-footer__content{
  position: relative;
  @include z-index(3);
}

.section-contacto__address {
  padding-right: 50%;

  color: $white;
  .summary {
    @include font-sans-bold(32px);
  }

  .vcard {
    @include font-sans($font-size-base);
    margin-bottom: 60px;

    a {
      @include color-link($white, $positive);
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.main-footer__legal {
  margin-top: 60px;
  text-align: center;

  a {
    @include font-sans($font-size-xsmall);
    @include color-link($white, $positive);
    text-decoration: underline;
    margin-left: 20px;
  }
}


@media (max-width: $tabletPortrait) {
  .section-contacto__address {
    padding-right: 0;
  }

  .main-footer {
    padding-left: 62px;
    padding-right: 62px;
  }
}

@media (max-width: $smartphone) {
  .main-footer {
    padding-left: $padding-sizes-mobile;
    padding-right: $padding-sizes-mobile;
    .title-marco {
      font-size: 24px;
    }
  }

  .main-footer__logo {
    opacity: 0.4;
    top:20px;
  }

  .section-contacto__address {
    .summary {
      font-size: $font-size-base;
    }
  }

  .main-footer__legal {
    a {
      display: block;
      margin: 20px 0;
    }
  }
}




