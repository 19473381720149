$sep: $padding-sizes;

#guides {
  position: fixed;
  @include z-index(9999999);
  pointer-events: none;
  width: 100%;
  height: 100%;
  top:0;
  left:0;

  > div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
  }

  span {
    flex: 1 0 auto;
    position: relative;
    display: block;
   // margin: 0 $sep/2;

    /*&:after {
      @include pseudo-element-absolute();
      width: 100%;
      height: 100%;
      background: rgba(#ff0000, 0.2);
    }*/

    &:after {
      @include pseudo-element-absolute();
      width: 2px;
      height: 100%;
      background: rgba(#ff0000, 0.2);
    }

    &:first-of-type {  margin-left: 0;  }
    &:last-of-type {
      margin-right: 0;
      &:before {
        @include pseudo-element-absolute();
        width: 2px;
        height: 100%;
        right: 0;
        background: rgba(#ff0000, 0.2);
      }
    }
  }

  .guides__main {
    position: absolute;

    height: 100%;
    left:50%;
    @include transform-3D(-50%,0,10px);
    display: flex;
    justify-content: space-between;

    span {
      &:after, &:before {
        background: rgba(#1E90FF, 1);
      }
    }
  }
}

@media (max-width: $layoutBrokeSize) {
  .guides__main {
    display: none !important;
  }
}