// SIZE
// -------------------------------
@mixin setSize($w,$h) {
  width: $w;
  height: $h;
}
@mixin setPosition($top:auto,$right:auto,$bottom:auto,$left:auto) {
  top:$top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin aspect-ratio($width, $height) {
  position: relative;
  width:100%;
  display: block;
  height: auto;
  padding-top: ($height / $width) * 100%;
}

@mixin aspect-ratio-width($width, $height, $widthDiv) {
  position: relative;
  display: block;
  width: $widthDiv;
  height: auto;
  padding-top: ($height / $width) * $widthDiv;
}

@mixin aspect-ratio-obj($width, $height) {

  &:before{
    display: block;
    content: " ";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
}

@mixin aspect-ratio-obj-width($width, $height,$widthDiv) {
  position: relative;
  width:100%;
  display: block;
  height: auto;

  &:before{
    display: block;
    content: " ";
    width: $widthDiv;
    height: auto;
    padding-top: ($height / $width) * $widthDiv;
  }
}

@mixin aspect-ratio-obj-after($width, $height) {
  position: relative;
  width:100%;
  display: block;
  height: auto;

  &:after{
    display: block;
    content: " ";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
}

@mixin aspect-ratio-obj-width-after($width, $height,$widthDiv) {
  position: relative;
  width:100%;
  display: block;
  height: auto;

  &:after{
    display: block;
    content: " ";
    width: $widthDiv;
    height: auto;
    padding-top: ($height / $width) * $widthDiv;
  }
}

@mixin aspect-ratio-img($width, $height) {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  padding-top: ($height / $width) * 100%;

  > img, video {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width:100%;
    height: auto;
  }
}

@mixin aspect-ratio-img-width($width, $height, $widthDiv) {
  position: relative;
  display: block;
  width: $widthDiv;
  height: auto;
  padding-top: ($height / $width) * $widthDiv;

  > img, video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width:100%;
    height: auto;
  }
}

.no-interaction {
  pointer-events: none;
}

// Z-Index
// -------------------------------
@mixin z-index($level) {
  z-index: $level;
  transform        : translateZ($level + px) ;
}


// Opacity
// -------------------------------

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

%visuallyhidden {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

.visually-hidden {
  @extend %visuallyhidden;
}

@mixin pseudo-element-absolute() {
  position: absolute;
  content:'';
  display:block;
}

//FLEX
@mixin flexbox-container($direction:row, $wrap:wrap) {
  display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;      /* TWEENER - IE 10 */
  display: -webkit-flex;     /* NEW - Chrome */
  display: flex;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -webkit-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;

  -webkit-flex-wrap: $wrap;
  -ms-flex-wrap: $wrap;
  flex-wrap: $wrap;
/*
  -webkit-flex-flow: $direction $wrap;
  flex-flow: $direction $wrap;*/
}
@mixin flex-size ( $ordinal, $fallback ) {
  -webkit-box-flex: $ordinal; // OLD - iOS 6-, Safari 3.1-6
  -moz-box-flex: $ordinal;    // OLD - Firefox 19-
  width: $fallback;           // OLD - Fallback to prevent collapse
  -webkit-flex: $ordinal;     // NEW - Chrome prefixed
  -ms-flex: $ordinal;         // NEW - IE prefixed
  flex: $ordinal;             // NEW - Proper Spec
}

@mixin flexbox-order($order) {
  -webkit-order: $order;
  -ms-flex-order: $order;
  order: $order;
}
@mixin flexbox-spacebetween() {
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
@mixin flexbox-justify-spacebetween() {
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
@mixin flexbox-justify-spacearound() {
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
@mixin flexbox-justify-center() {
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
@mixin flexbox-justify-flexstart() {
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
@mixin flexbox-justify-flexend() {
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
@mixin flexbox-align-content-stretch() {
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}
@mixin flexbox-align-items-start() {
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}
@mixin flexbox-align-self-stretch() {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}
@mixin flexbox-align-self-end() {
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
}
@mixin flexbox-align-self-start() {
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}


@mixin flexbox-align-items($n) {
  -webkit-align-items: $n;
  -ms-flex-align: $n;
  align-items: $n;
}
@mixin flexbox-align-order($n) {
  -webkit-order: $n;
  -ms-flex-order: $n;
  order: $n;
}
@mixin flexbox-item-flex($n1, $n2, $min) {
  -webkit-flex: $n1 $n2 $min;
  -ms-flex: $n1 $n2 $min;
  flex: $n1 $n2 $min;
}


// Colors
// -------------------------------

@each $color-name, $color in $colors {
  .color#{$color-name} {  color: #{$color};  }
  .bg#{$color-name} {  background-color: #{$color};  }
  .border#{$color-name} {  border-color: #{$color};  }
  .stroke#{$color-name} {  stroke: #{$color};  }
  .fill#{$color-name} {  fill: #{$color};  }
}

// Deco
// -------------------------------

.line {
  position: relative;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #FFFFFF;
}

// BORDERS
// -------------------------------

@for $i from 0 through 10 {
  .border-#{$i} { border: #{$i}px solid;}
  .border-bottom-#{$i} { border-bottom: #{$i}px solid;}
  .border-left-#{$i} { border-left: #{$i}px solid;}
  .border-right-#{$i} { border-right: #{$i}px solid;}
}

// MARGIN PADDING
// -------------------------------

@mixin clearfix() {
  &:before,
  &:after {
    clear: both;
    content: " ";
    display: table;
  }
}


.no-padding {
  padding: 0;
}

.no-margin {
  margin: 0;
}

.margin-top-100 { margin-top: 100px; }
.margin-top-90 { margin-top: 90px; }
.margin-top-80 { margin-top: 80px; }
.margin-top-70 { margin-top: 70px; }
.margin-top-60 { margin-top: 60px; }
.margin-top-50 { margin-top: 50px; }
.margin-top-40 { margin-top: 40px; }
.margin-top-30 { margin-top: 30px; }
.margin-top-20 { margin-top: 20px; }
.margin-top-10 { margin-top: 10px; }

.margin-bottom-100 { margin-bottom: 100px; }
.margin-bottom-90 { margin-bottom: 90px; }
.margin-bottom-80 { margin-bottom: 80px; }
.margin-bottom-70 { margin-bottom: 70px; }
.margin-bottom-60 { margin-bottom: 60px; }
.margin-bottom-50 { margin-bottom: 50px; }
.margin-bottom-40 { margin-bottom: 40px !important; }
.margin-bottom-30 { margin-bottom: 30px; }
.margin-bottom-20 { margin-bottom: 20px; }
.margin-bottom-10 { margin-bottom: 10px; }

.margin-left-100 { margin-left: 100px; }
.margin-left-90 { margin-left: 90px; }
.margin-left-80 { margin-left: 80px; }
.margin-left-70 { margin-left: 70px; }
.margin-left-60 { margin-left: 60px; }
.margin-left-50 { margin-left: 50px; }
.margin-left-40 { margin-left: 40px; }
.margin-left-30 { margin-left: 30px; }
.margin-left-20 { margin-left: 20px; }
.margin-left-10 { margin-left: 10px; }

.margin-right-100 { margin-right: 100px; }
.margin-right-90 { margin-right: 90px; }
.margin-right-80 { margin-right: 80px; }
.margin-right-70 { margin-right: 70px; }
.margin-right-60 { margin-right: 60px; }
.margin-right-50 { margin-right: 50px; }
.margin-right-40 { margin-right: 40px; }
.margin-right-30 { margin-right: 30px; }
.margin-right-20 { margin-right: 20px; }
.margin-right-10 { margin-right: 10px; }

.padding-top-100 { padding-top: 100px; }
.padding-top-90 { padding-top: 90px; }
.padding-top-80 { padding-top: 80px; }
.padding-top-70 { padding-top: 70px; }
.padding-top-60 { padding-top: 60px; }
.padding-top-50 { padding-top: 50px; }
.padding-top-40 { padding-top: 40px; }
.padding-top-30 { padding-top: 30px; }
.padding-top-20 { padding-top: 20px; }
.padding-top-10 { padding-top: 10px; }

.padding-bottom-100 { padding-bottom: 100px; }
.padding-bottom-90 { padding-bottom: 90px; }
.padding-bottom-80 { padding-bottom: 80px; }
.padding-bottom-70 { padding-bottom: 70px; }
.padding-bottom-60 { padding-bottom: 60px; }
.padding-bottom-50 { padding-bottom: 50px; }
.padding-bottom-40 { padding-bottom: 40px; }
.padding-bottom-30 { padding-bottom: 30px; }
.padding-bottom-20 { padding-bottom: 20px; }
.padding-bottom-10 { padding-bottom: 10px; }

.padding-left-100 { padding-left: 100px; }
.padding-left-90 { padding-left: 90px; }
.padding-left-80 { padding-left: 80px; }
.padding-left-70 { padding-left: 70px; }
.padding-left-60 { padding-left: 60px; }
.padding-left-50 { padding-left: 50px; }
.padding-left-40 { padding-left: 40px; }
.padding-left-30 { padding-left: 30px; }
.padding-left-20 { padding-left: 20px; }
.padding-left-10 { padding-left: 10px; }

.padding-right-100 { padding-right: 100px; }
.padding-right-90 { padding-right: 90px; }
.padding-right-80 { padding-right: 80px; }
.padding-right-70 { padding-right: 70px; }
.padding-right-60 { padding-right: 60px; }
.padding-right-50 { padding-right: 50px; }
.padding-right-40 { padding-right: 40px; }
.padding-right-30 { padding-right: 30px; }
.padding-right-20 { padding-right: 20px; }
.padding-right-10 { padding-right: 10px; }

// SIZES
// -------------------------------

.w-100 { width: 100%; }
.w-90 { width: 90%; }
.w-80 { width: 80%; }
.w-70 { width: 70%; }
.w-60 { width: 60%; }
.w-50 { width: 50%; }
.w-40 { width: 40%; }
.w-30 { width: 30%; }
.w-20 { width: 20%; }
.w-10 { width: 10%; }

.h-100 { height: 100%; }
.h-90 { height: 90%; }
.h-80 { height: 80%; }
.h-70 { height: 70%; }
.h-60 { height: 60%; }
.h-50 { height: 50%; }
.h-40 { height: 40%; }
.h-30 { height: 30%; }
.h-20 { height: 20%; }
.h-10 { height: 10%; }

// FONT
// -------------------------------

.align-left {
  text-align: left;
}
.align-center {
  text-align: center;
}
.align-right {
  text-align: right;
}

$browser-context: 16;
@function em($pixels, $context: $browser-context) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1em;
}



.full-height{
  height: 100%;
  min-height: 100% !important;
}
.img-loading {display: none;}
