@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}
@mixin animation-name($name...) {
  -webkit-animation-name: $name;
  animation-name: $name;
}
@mixin animation-duration($duration...) {
  -webkit-animation-duration: $duration;
  animation-duration: $duration;
}
@mixin animation-duration($duration...) {
  -webkit-animation-duration: $duration;
  animation-duration: $duration;
}
@mixin animation-timing-function($timing...) {
  -webkit-animation-timing-function: $timing;
  animation-timing-function: $timing;
}
@mixin animation-iteration-count($count...) {
  -webkit-animation-iteration-count: $count;
  animation-iteration-count: $count;
}
@mixin animation-direction($direction...) {
  -webkit-animation-direction: $direction;
  animation-direction: $direction;
}
@mixin animation-fill-mode($fill...) {
  -webkit-animation-fill-mode: $fill;
  animation-fill-mode: $fill;
}
@mixin animation-play-state($state...) {
  -webkit-animation-play-state: $state;
  animation-play-state: $state;
}
@mixin animation($animation...) {
  -webkit-animation: $animation;
  animation: $animation;
}


@mixin transition($args...) {
  transition: $args;
}

@mixin transform-origin($p1, $p2){
  -webkit-transform-origin: $p1 $p2;
  -ms-transform-origin: $p1 $p2;
  transform-origin: $p1 $p2;
}



@mixin transform-rotate($val){
  @if($val != null){
    -webkit-transform: rotate($val + deg) ;
    -ms-transform    : rotate($val + deg) ;
    transform        : rotate($val + deg) ;
  }
}
@mixin transform-scale($x:1, $y:1){
  -webkit-transform: scale($x, $y);
  -ms-transform    : scale($x, $y);
  transform        : scale($x, $y);
}
@mixin transform-skew($x:0, $y:0){
  -webkit-transform: skew($x + deg, $y + deg) ;
  -ms-transform    : skew($x + deg, $y + deg) ;
  transform        : skew($x + deg, $y + deg) ;
}
@mixin transform-translate($x, $y) {
  @if ($x != null) {
    -webkit-transform: translateX($x);
    -ms-transform: translateX($x);
    transform: translateX($x);
  }
  @if ($y != null) {
    -webkit-transform: translateY($y);
    -ms-transform: translateY($y);
    transform: translateY($y);
  }
}
@mixin transform-3D($x:0, $y:0, $z:0){
  -webkit-transform: translate3D($x,$y,$z) ;
  -ms-transform    : translate3D($x,$y,$z) ;
  transform        : translate3D($x,$y,$z) ;
}
@mixin transformZ($z){
  -webkit-transform: translateZ($z) ;
  -ms-transform    : translateZ($z) ;
  transform        : translateZ($z) ;
}

@mixin transformY($n){
  -webkit-transform: translateY($n) ;
  -ms-transform    : translateY($n) ;
  transform        : translateY($n) ;
}

@mixin transformX($n){
  -webkit-transform: translateX($n) ;
  -ms-transform    : translateX($n) ;
  transform        : translateX($n) ;
}


.anim-opacity {
  @include opacity(0);
}
.anim-opacity.show-anim {
  @include opacity(1);
  @include transition(all 1s cubic-bezier(0.075, 0.820, 0.165, 1.000) 0.0s);
}

.anim-up {
  @include transform-3D(0, 400px, 0);
}
.anim-up.show-anim {
  @include transform-3D(0, 0, 0);
  @include transition(all 1s cubic-bezier(0.075, 0.820, 0.165, 1.000) 0.0s);
}
.anim-bottom {
  @include transform-3D(0, -40%, 0);
}
.anim-bottom.show-anim {
  @include transform-3D(0, 0, 0);
  @include transition(all 0.6s cubic-bezier(0.075, 0.820, 0.165, 1.000) 0.0s);
}

.anim-left {
  @include transform-3D(40%, 0, 0);
}
.anim-left.show-anim {
  @include transform-3D(0, 0, 0);
  @include transition(all 0.6s cubic-bezier(0.075, 0.820, 0.165, 1.000) 0.0s);
}

.anim-right {
  @include transform-3D(-40%, 0, 0);
}
.anim-right.show-anim {
  @include transform-3D(0, 0, 0);
  @include transition(all 0.6s cubic-bezier(0.075, 0.820, 0.165, 1.000) 0.0s);
}

