$padding-sizes: 35px;
$padding-sizes-mobile: 24px;
$padding-sizes-tablet: 24px;

$container-xs: 500px;
$container-s: 680px;
$container-m: 840px;
$container-l: 1250px;
$container-xl: 1250px;
$container-max: 1250px;

$padding-layout: 25%;
$container-m-porc: 50%;
$container-l-porc: 66%;
$container-xl-porc: 90%;


$layoutBrokeSize: ($container-m*2)+$padding-sizes*2;


$containers-sizes: (
        -xsmall: $container-xs,
        -small: $container-s,
        -mid: $container-m,
        -large: $container-l,
        -xlarge: $container-xl,
        -max:$container-max,
);
$containers-porc: (
        -mid: $container-m-porc,
        -large: $container-l-porc,
        -xlarge: $container-xl-porc,
);

.container-full {
  position: relative;
  width: 100%;
  height: auto;
  margin: 0px auto;
}
.container-full-padding {
  @extend .container-full;
  padding-left: $padding-sizes;
  padding-right: $padding-sizes;
  //box-sizing: initial;
}
.container-custom {
  position: relative;
  width: 100%;
  height: auto;
  margin: 0px auto;
}

@each $size-name, $size in $containers-sizes {
  .container#{$size-name} {
    @extend .container-full;
    max-width: #{$size};
  }
  .container#{$size-name}-padding {
    @extend .container-full-padding;
    max-width: #{$size + $padding-sizes*2};
  }
}
@each $size-name, $size in $containers-porc {
  .container#{$size-name}-percentage {
    @extend .container#{$size-name};
    width: #{$size};
  }
  .container#{$size-name}-padding-percentage {
    @extend .container#{$size-name}-padding;
    width: #{$size};
  }
  @media (max-width: $layoutBrokeSize) {

  }
}

@media (max-width: $menuHideSize) {
  .container-full-padding{
    padding-left: $padding-sizes-tablet;
    padding-right: $padding-sizes-tablet;
  }
  .container-mid-percentage,
  .container-mid-padding-percentage {
    width:75%;
  }
}
@media (max-width: $smartphone) {
  .container-full-padding{
    padding-left: $padding-sizes-mobile;
    padding-right: $padding-sizes-mobile;
    width:100% !important;
  }
  .container-full {
    width:100%;
  }
}


.container-full-layout {
  padding-left: calc((100% - 840px) / 2);
}
@media (max-width: $layoutBrokeSize) {
  .container-layout,
  .container-full-layout {
    padding-left: $padding-layout;
  }
}

@media (max-width: $desktop) {
  .container-layout,
  .container-full-layout {
    padding-left: 0;
    margin: 0 auto 0 0;
  }
}

@media (min-width: 1800px) {
  .container-wrap {

  }
}

