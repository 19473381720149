
/* A underline */

@include keyframes(underline-none) {
  0%   {
    left:0px;
    width: 0%;
  }
  100%  {
    left:0px;
    width: 0%;
  }
}

@include keyframes(underline-in) {
  0%   {
    left:0px;
    width: 0%;
  }
  100%  {
    width: 100%;
  }
}

@include keyframes(underline-out) {
  0%   {
    left:0px;
    width: 100%;
  }
  100%  {
    left:100%;
    width: 0%;
  }
}

@include keyframes(underline-linkctive) {
  0%   {
    left:0px;
    width: 100%;
  }
  100%  {
    left:0px;
    width: 100%;
  }
}

@include keyframes(underline-disabled) {
  0%   {
    left:0px;
    width: 0%;
  }
  100%  {
    left:0px;
    width: 0%;
  }
}

@include keyframes(underline-inout) {
  0%   {
    left:0px;
    width: 0%;
  }
  47%  {
    left:0px;
    width: 100%;
  }
  50% {
    left:0px;
    width: 100%;
  }
  97% {
    left:100%;
    width: 0%;
  }
}

/* DEFAULT */

a > .content {
  position: relative;
}

%default-a {
  position:relative;
  outline: 0;
  padding: 0;
  margin: 0;
  text-decoration: none;
  @include transition(all 1s cubic-bezier(0.020, 0.560, 0.140, 0.940));

  &:hover {
    text-decoration: none;
    @include transition(all 0.2s cubic-bezier(0.020, 0.560, 0.140, 0.940));
  }
}

@mixin default-a($color:$black, $color-hover:$assertive) {
  @extend %default-a;
  color:$color;
  &:hover {
    color: $color-hover;
  }
}


.no-effect-link,
%no-effect-link {
  position: relative;
  outline: 0;
  padding: 0;
  margin: 0;
  border: 0;
  background: transparent;
  text-decoration: none;
  &:hover {  text-decoration: none;  }
}

@mixin no-effect-link() {
  @extend %no-effect-link;
}


%splitetext-link {
  outline: 0;
  padding: 0;
  margin: 0;
  text-decoration: none;
  &:hover {  text-decoration: none;  }
}

@mixin splitetext-link() {
  @extend %splitetext-link;
  position: relative;
  text-transform: uppercase;
  color: $white;

  span {
    display: block;
    color: $transparent;
    padding-right: 1em;
  }
  .holder {
    position: relative;
    pointer-events: none;

  }
  .splitLine {
    width: 100%;
    position: absolute;
    left:0;
    top:0;
  }
}