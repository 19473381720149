.image-zoom-mask {
  position: relative;
  perspective: 1000px;
  overflow: hidden;
  //background: $positive;

  div, img {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    transform : translate3D(0,100px, 600px) ;
  }

  &.show-anim {
    > div, img {
      opacity: 1;
      transform : translate3D(0,0, 0) ;
      transition:
              transform 3000ms cubic-bezier(0.050, 0.045, 0.210, 0.970),
              opacity 1000ms ease 0ms;
    }
  }
}