
@mixin color-link($color:$black, $hover:$assertive) {
  outline: 0;
  text-decoration: none;
  color: $color;

  transition: color .4s cubic-bezier(0.165, 0.840, 0.440, 1.000);

  &:hover {
    color: $hover;
    transition: color .0s;
  }

  &:active {
    color: $color;
  }

  &.__linkActive {
    pointer-events: none;
    color: $hover;
  }

  &.__linkActiveEnabled {
    color: $hover;
  }

  &.link-disabled{
    pointer-events: none;
  }
}





