.ul-square-size5 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin-top: 2em;
  @include font-sans-bold($font-size-small, 1);
  border: 1px solid $assertive;

  li {
    position: relative;
    flex: 1 0 20%;
    @include aspect-ratio-obj(1,1);
    width: 20%;
    background: transparent;
    color: $assertive;
    fill: $assertive;
    border: 2px solid $assertive;


    img, svg {
      position: absolute;
      left:0;
      top:0;
      width: 100%;
      height: 100%;
    }
    > span {
      position: absolute;
      text-align: center;
      width: 100%;
      left:0;
      bottom:10%;
    }
  }
}

@media (max-width: 1100px) {

}

@media (max-width: $smartphone) {
  .ul-square-size5 {
    li {
      flex: 1 0 50%;
      width: 50%;
    }
  }
}
