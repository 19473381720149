
.section-team {
  padding-bottom: 100px;
}

.slider-scroll__item__info {
  padding: 20px 60px;

  span {
    display: block;
    @include font-sans-bold($font-size-base);
  }
  a {
    &:first-of-type {
      margin-top: 5px;
    }
    @include font-sans($font-size-base);
    @include stroke-link($assertive,$assertive,3px);
  }
}

@media (max-width: $smartphone) {
  .section-team {
    padding-bottom: 80px;
  }

  .slider-scroll__item__info {
    padding: 20px;
  }
}