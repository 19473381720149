

.sidemenu-holder {
  position: fixed;
  left:0;
  top:0;
  width: 250px;
  height: 100vh;
  @include z-index($z-index-sidemenu)
}

.main-sidemenu {
  position: absolute;
  top:0;
  left:0;
  @include font-sans-bold(18px, 1);
  height: 100vh;
  display: flex;
  align-items: center;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    padding: 0 0 0 50px;
  }

  a {
    position: relative;
    @include z-index(3);
    @include no-effect-link();
    display: block;
    color: $assertive;
    padding: 20px 0;

    &.hovered {
      color: $white;
    }

    &:before {
      @include pseudo-element-absolute();
      width: 160px;
      height: 30px;
      border: 3px solid $assertive;
      left:-180px;
      top:15px;
    }

    &.__linkActive {
      &:before {
        background: $assertive;
      }
    }
  }
}




@media (max-width: $tabletPortrait) {
  .sidemenu-holder {
    display: none;
  }
}


