.section-summary {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;

  padding-top: 50vh;

  .section__image {
    width: 40%;
  }

  .section__content {
    width: 50%;
  }

  .title-marco {
    font-size: 48px;
  }

  p {
    max-width: 400px;
  }
}

@media (max-width: 900px) {
  .section-summary {
    .section__image {
      width: 40%;
    }

    .section__content {
      width: 58%;
    }
  }
}

@media (max-width: $tabletLandscape) {
  .section-summary {
    .title-marco {
      font-size: 32px;
    }
  }
}

@media (max-width: $tabletPortrait) {
  .section-summary {

    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .section__image {
      width: 80%;
    }

    .section__content {
      width: 60%;
      align-self: flex-end;
    }

    .title-marco {
      margin: 0.7em 0;
    }
  }
}

@media (max-width: $smartphone) {
  .section-summary {
    .section__image, .section__content {
      width: 100%;
    }
    .title-marco {
      font-size: 24px;
    }
  }
}