
.section-with-sidemenu {
  @include default-section();

  padding-left: $aside-menu-width;

  &:before {
    @include pseudo-element-absolute();
    z-index: 1;
    width: 100%;
    height: calc(100% - 85vh - 50px);
    top: 85vh;
    left: 220px;
    border: 3px solid $assertive;
  }

  .section__header {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 50vh;

    h2 {
      position: absolute;
      margin: 0;
      padding: 0;
      font-family: $font-sans-compressed;
      font-size: 80vh;
      color: $white;
    }
  }
  .section__content {
    z-index: 3;
    color: $assertive;

    h3,p,.normal-ul,ol {
      width: 90%;
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
    }

    h3 {
      margin-top: 2em;
      margin-bottom: 2em;
      @include font-sans-bold($font-size-title, 1);
      &:after {
        @include pseudo-element-absolute();
        left:0;
        width: calc((100% - 600px) / 2);
        border-bottom: 3px solid $assertive;
        height: 1em;
      }
    }
    p {
      @include default-p();
    }
    .normal-ul {
      @include default-p();
      padding: 0;
      list-style: none;
      li {
        margin: 0;
        padding: 0;
      }
    }
  }

  figure {
    &:last-child {
      font-size: $font-size-title;
      margin-top: 2em;
    }
  }
}


@media (max-width: $tabletPortrait) {
  .section-with-sidemenu {
    padding-left: 120px;

    &:before {
      height: calc(100% - 30vh - 50px);
      top: 30vh;
      left: 60px;
    }

    .section__header {
      height: 20vh;

      h2 {
        right: -48px;
        font-size: 30vh;
      }
    }

    .section__content {
      h3,p,.normal-ul,ol {
        width: 90%;
        max-width: 600px;
        margin-left: auto;
        margin-right: 0;
      }

      h3 {
        &:after {
          width: 10%;
        }
      }

    }
  }
}

@media (max-width: $smartphone) {
  .section-with-sidemenu {
    padding-left: 38px;
    padding-right: 10px;

    &:before {
      height: calc(100% - 20vh - 50px);
      top: 20vh;
      left: 10px;
    }

    .section__header {
      height: 10vh;

      h2 {
        right: -10px;
        font-size: 20vh;
      }
    }

    .section__content {
      p,.normal-ul,ol {
        width: 100%;
        padding-right: 10px;
      }

      h3 {
        width: 100%;
        font-size: $title-size-smartphone;
        &:after {
          display: none;
        }
      }
    }
  }
}


//BIG
@media (min-width: 1500px) {
  .section-with-sidemenu {
    padding-left:0;

    &:before {
      width: calc(100% + 440px);
      left: -220px;
    }
  }
}