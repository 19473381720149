.ul-square {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin-top: 2em;
  @include font-sans-bold($font-size-title, 1);

  li {
    position: relative;
    flex: 1 0 50%;
    @include aspect-ratio-obj(4,3);
    width: 50%;

    &:nth-child(1) {
      border-top: 3px solid $assertive;
      border-left: 3px solid $assertive;
      border-right: 3px solid $assertive;
    }

    &:nth-child(2) {
      border-top: 3px solid $assertive;
      border-right: 3px solid $assertive;
    }

    &:nth-child(3) {
      border-top: 3px solid $assertive;
      border-bottom: 3px solid $assertive;
      border-left: 3px solid $assertive;
      border-right: 3px solid $assertive;
    }

    &:nth-child(4) {
      border-top: 3px solid $assertive;
      border-bottom: 3px solid $assertive;
      border-right: 3px solid $assertive;
    }

    > div {
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 40px;
    }
  }
}

@media (max-width: 1100px) {
  .ul-square {
    font-size: $font-size-title-smartphone;
  }
}
@media (max-width: 990px) {
  .ul-square {
    font-size: 20px;
  }
}

@media (max-width: $smartphone) {
  .ul-square {
    li {
      flex: 1 0 100%;
      width: 100%;

      border-top: 3px solid $assertive;
      border-left: 3px solid $assertive;
      border-right: 3px solid $assertive;
      border-bottom: 0 !important;

      &:last-of-type {
        border-bottom: 3px solid $assertive  !important;
      }
    }
  }
}

//BIG
/*
@media (min-width: 1350px) {
  .ul-square {
    font-size: 2.5vw;
  }
}*/