.cursor {
  position: fixed;
  @include z-index($z-index-cursor);
  top:0;
  left:0;
  width: 1px;
  height: 1px;
  will-change: transform;
  pointer-events: none;

  mix-blend-mode: difference;

  opacity: 1;
  transition: opacity 300ms cubic-bezier(0.250, 0.250, 0.750, 0.750);

  > div {
    position: absolute;
    top:0;
    left:0;
    width: 10px;
    height: 10px;
    @include transform-3D(-50%, -50%, 1px);
    border-radius: 50%;
    background: $assertive;
    color: $assertive;
  }

  img {
    position: absolute;
    top:0;
    left:0;
    @include transform-3D(-50%, -50%, 4px);
  }

  &.scroll-advise {
    opacity: 0;
  }
}



.cursor__follower {
  position: fixed;

  @include z-index($z-index-follower);
  top:0;
  left:0;
  width: 1px;
  height: 1px;
  will-change: transform;
  pointer-events: none;
  transition: transform 500ms cubic-bezier(0.040, 0.960, 0.320, 1.000);


  > div {
    position: absolute;
    top:0;
    left:0;
    width: 40px;
    height: 40px;
    @include transform-3D(-50%, -50%, 1px);
    transition:
            background 300ms
            cubic-bezier(0.040, 0.960, 0.320, 1.000),
            width 300ms cubic-bezier(0.040, 0.960, 0.320, 1.000),
            height 300ms cubic-bezier(0.040, 0.960, 0.320, 1.000),
            border-radius 300ms cubic-bezier(0.040, 0.960, 0.320, 1.000),
            border-width 0ms;

    border-radius: 50%;
    background: transparent;
    border-width: 2px;
    border-style: solid;
    border-color: $assertive;
    background: $assertive;
    color: $assertive;
  }

  &.scroll-advise {
    > div {
      &:after {
        width: 100%;
        padding-top: 12px;
        text-align: center;
        @include pseudo-element-absolute();
        content: 'SCROLL DOWN';
        @include font-sans-bold(12px, 1);
      }
    }
  }

  &.fixed {
    > div {
      /*width: 60px;
      height: 60px;*/
      background: rgba($white, 0.2);
      transition:
              background 300ms cubic-bezier(0.040, 0.960, 0.320, 1.000),
              width 300ms cubic-bezier(0.040, 0.960, 0.320, 1.000),
              height 300ms cubic-bezier(0.040, 0.960, 0.320, 1.000),
              border-width 0ms;
    }
  }
}

