

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
* html .clearfix             { zoom: 1; } /* IE6 */
*:first-child+html .clearfix { zoom: 1; } /* IE7 */

/** {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing:grayscale;
}*/
/*body > * {
  -webkit-transform: translate3d(0,0,0);
}*/
input,button, embed, video, object {
  outline: none;
  border: 0px solid;
}
html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing:grayscale;
  text-rendering: optimizeLegibility;
}
button {
  -webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
  user-select: none;          /* Likely future */
  -webkit-touch-callout: none;
  -moz-touch-callout: none;
  -ms-touch-callout: none;
  touch-callout: none;
}
/*div {
  -webkit-touch-callout: none;
  -moz-touch-callout: none;
  -ms-touch-callout: none;
  touch-callout: none;
}*/
/*img {
  width: 100%;
  height: auto;
}*/
.media-holder{
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
  background-position: center center;
  background-size: cover;

  video, img, .pixi, .__bg {
    position: absolute;
    top:0px;
    left:0px;
    width: 100%;
    height: 100%;
  }

  .__bg {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
  }
}