.ul-square-size3 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin-top: 2em;
  @include font-sans-bold($font-size-small, 1);
  border: 1px solid $assertive;

  li {
    position: relative;
    flex: 1 0 33.333%;
    @include aspect-ratio-obj(1,1);
    width: 33.333%;

    border: 2px solid $assertive;

    &:nth-child(odd) {
      background: $assertive;
      color: $white;
      fill: $white;
    }

    &:nth-child(even) {
      background: transparent;
      color: $assertive;
      fill: $assertive;
    }

    img, svg {
      position: absolute;
      left:0;
      top:0;
      width: 100%;
      height: 100%;
    }
    > span {
      position: absolute;
      text-align: center;
      width: 100%;
      left:0;
      bottom:10%;
    }
  }
}

@media (max-width: $smartphone) {
  .ul-square-size3 {
    li {
      flex: 1 0 100%;
      width: 100%;

      &:nth-child(odd) {
        background: transparent;
        color: $assertive;
        fill: $assertive;
      }

      &:nth-child(even) {
        background: transparent;
        color: $assertive;
        fill: $assertive;
      }
    }
  }
}

@media (max-width: $smartphone) {
  .ul-square-size3 {

  }
}
