.svg-deco {
  position: fixed;
  top:50vh;
  left:60px;
  width: 100%;
  height: 80vh;
  fill: $transparent;
  stroke: $assertive;
  stroke-width: 1px;

  svg {
    height: 100%;
    width: auto;
  }
}