.loader {
  position: fixed;
  @include z-index($z-index-loader);
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.loader__logo-holder {
  position: absolute;
  @include z-index(2);
  perspective: 800px;

  svg {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    fill:$white;
    transform : translate3D(0,0,0) ;
    will-change: transform;
  }
}

.loader__progress {
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background: $assertive;
}

