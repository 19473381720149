
// Cookies
// -------------------------------

.cookies-alert {
  position: fixed;
  @include z-index($z-index-cookies);
  bottom:0px;
  left:0px;
  display: none;
  width: 100%;
  background: $white;
  color: $white;
  cursor: auto;
  border-top: 3px solid $assertive;
}

.button-accept-cookies {
  @include font-sans-bold($font-size-large);
  height: 60px;
  width: 200px;
  flex: 0 0 200px;
  color: $assertive;
  background: $white;

  outline: 0;
  border: 3px solid $assertive;
  border-radius: 0;
  text-decoration: none;

  margin-left: 40px;


  cursor: pointer;

  &:hover {
    color: $white;
    background: $black;
    border-color: $black;
  }
}

.cookies-alert-holder{
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  p {
    @include font-sans($font-size-small);
    color: $black;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    b, strong {
      font-size: $font-size-base;
      color: $assertive;
    }

    a {
      @include underline-link($assertive, $assertive, $assertive, $assertive);
    }
  }
}

.cookies-alert-content{
  top:0px;
  left:0px;
  padding: 0;
}

@media (max-width: $smartphone) {

  .cookies-alert-holder{
    flex-direction: column;
  }

  .button-accept-cookies {
    margin: 20px 0 0;
    width: 100%;
  }

}