.section-header {
  @include default-section();
  margin-bottom: 200px;

  &:after {
    @include pseudo-element-absolute();
    z-index: 2;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    border: 3px solid $assertive;
  }
  .section__content {
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }

  .title-marco-ani {
    font-size: 10vh;
    color: $white;

    .title-marco__line {
      &:after {
        border-color: $white;
      }
    }
  }
}



@media (max-width: $tabletPortrait) {
  .section-header {
    .section__content {
      align-items: center;
    }
    .title-marco-ani {
      font-size: 70px;
    }
  }
}
@media (max-width: $smartphone) {
  .section-header {
    margin-bottom: 40px;
    .title-marco-ani {
      font-size: 32px;
    }
  }
}

.section-header__scroll-indicator {
  position: absolute;
  width: 90px;
  height: 90px;
  z-index: 4;
  left:50%;
  margin-left: -45px;
  bottom:40px;
  outline: 0;
  border: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @include font-sans-bold(14px,1);
  color: $white;

  .arrow-down:first-of-type {
    height: 60px;
  }
  .arrow-down:last-of-type {
    height: 30px;
  }
}

.section-header__footer {
  position: absolute;
  z-index: 3;
  left: 0;
  bottom: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;

  a {
    outline: 0;
    text-decoration: none;
    color: $white;
    font-size: 32px;
    margin-right: 20px;

    &:hover {
      color: $assertive;
    }
  }
}

@media (max-width: $smartphone) {
  .section-header__scroll-indicator {
    .arrow-down:first-of-type {
      height: 30px;
    }
    .arrow-down:last-of-type {
      height: 20px;
    }
  }

  .section-header__footer {
    display: none;
  }
}