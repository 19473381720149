%stroke-link {
  position: relative;
  cursor: pointer;
  display: inline-block;
  outline: 0;
  text-decoration: none;

  &:before {
    position: absolute;
    display: block;
    content: '';

    bottom: 0px;
    left:0px;

    width: 100%;
    height: 85%;

    @include animation-name(underline-out);
    @include animation-duration(1s);
    @include animation-fill-mode(forwards);
    @include animation-timing-function(cubic-bezier(0.190, 1.000, 0.220, 1.000));
  }



  &.link-active {
    pointer-events: none;
    &:before {
      @include animation-name(underline-linkctive);
      @include animation-duration(0.0s);
      @include animation-fill-mode(forwards);
    }
  }

  &.loading {
    pointer-events: none;
    &:before {
      @include animation-name(underline-inout);
      @include animation-duration(1s);
      @include animation-fill-mode(forwards);
      @include animation-timing-function(cubic-bezier(0.190, 1.000, 0.220, 1.000));
      @include animation-iteration-count(999);
    }
  }
}

.__cursor {
  .stroke-link,
  %stroke-link {
    &:hover {
      text-decoration: none;

      &:before {
        @include animation-name(underline-in);
        @include animation-duration(0.8s);
        @include animation-fill-mode(forwards);
        @include animation-timing-function(cubic-bezier(0.190, 1.000, 0.220, 1.000));
      }
    }
  }
}

@mixin stroke-link($color:$black, $barColor:$assertive, $barHeight:85%) {
  @extend %stroke-link;
  color:$color;
  &:before {
    background-color: $barColor;
    height: $barHeight;
    bottom: -0.2em;
  }
}