.title-marco {
  font-family: $font-sans-bold;
  font-weight: 700;
  line-height: 1;
  margin: 0;
  color: $assertive;

  span {
    display: inline-block;
    width: auto;
    border: 3px solid $assertive;
    margin-top: -3px;
    padding: 0 .2em;

    &:first-of-type {
      margin-top: 0;
    }
  }
}

.title-marco-ani {
  font-family: $font-sans-bold;
  font-weight: 700;
  line-height: 1;
  margin: 0;
  color: $assertive;

  .title-marco__line {
    position: relative;
    display: block;
    width: auto;

    margin-top: -3px;
    padding: 0 .2em;
    overflow: hidden;

    &:after {
      @include pseudo-element-absolute();
      top:0;
      left:0;
      width: 100%;
      height: 100%;
      border: 3px solid $assertive;
    }

    &.__hide {
      &:after {
        display: none;
      }
    }
    span {
      position: relative;
      display: block;
      transform: translate3D(0,200%,1px);
    }
    &:first-of-type {
      margin-top: 0;
    }
    .__mask {
      background: $white;
    }
  }
}