
.slider-images {
  padding: 0;//14.28vh 0 0 0;
  margin-left: -125px;
  margin-bottom: 5.75em;

  .slider-scroll__holder-items {
    @include z-index($z-index-footer);
    padding: 0;
    height: 100vh;//calc(100vh - 14.28vh);
    padding: 50px 0 150px;
  }

  .slider-scroll__item {
    margin-left: -3px;
  }

  .image-holder {
    position: relative;

    figure {
      position: absolute;
      overflow: hidden;
      left:0;
      bottom:0;
      margin: 0;
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: auto;
      }

      &:after {
        @include pseudo-element-absolute();
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        border: 3px solid $assertive;
      }
    }
  }
}

@media (max-width: 1200px) {

}


@media (max-width: $tabletPortrait) {
  .slider-images {
    margin-left: 0;
  }
}


@media (max-width: $smartphone) {
  /*.slider-images {
    padding-top: 48px;
    height: auto;
    margin-bottom: 0;


    .slider-scroll__holder-items {
      position: relative;
      top:0;
      bottom: 0;
      left:0;
      height: auto;
      display: flex;
      flex-direction: column;
      padding: 0;
      will-change: transform;
    }

    .slider-scroll__item {
      padding: 0;
      margin-bottom: 24px;

      .image-holder {
        //@include aspect-ratio-obj(1284, 912);
        img {
          position: relative;
          width: 100%;
          height: auto;
        }
      }

      &.fake {
        display: none;
      }
      &:last-of-type {
        //display: none;
      }
    }
  }

  .featured-item__projects-preview {
    display: block;
    margin-top: 0 !important;
    text-align: center;
  }*/
}