
$button-link-size:15px;

@mixin button-link($color:$black, $colorHover:$white, $barColor:$transparent, $barHover: $black, $border:$black, $borderHover:$black, $fontSize:$font-size-large) {
  position: relative;
  display: inline-block;
  outline: 0;
  border: 0 solid transparent;
  border-radius: 0;
  text-decoration: none;
  padding: 0.6em 1em 0.6em;

  color: $color;
  background: $barColor;
  border: 1px solid $border;

  transition: all .8s cubic-bezier(0.165, 0.840, 0.440, 1.000);

  &:before,
  &:after {
    @include pseudo-element-absolute();
    width: 1px;
    top:0px;
    height: 70%;
    background-color: $black;
  }

  &:before {  left: -10px;  }
  &:after {  right: -10px;  }

  &:hover {
    color: $colorHover;
    background-color: $barHover;
    border-color: $borderHover;
    text-decoration: none;
    transition: all .4s cubic-bezier(0.165, 0.840, 0.440, 1.000);
  }


  &.link-active {
    pointer-events: none;
    &:before {
      transition: all 0s;
    }
  }

  &.link-active-enabled {
    &:before {
      transition: all 0s;
    }
  }

  &.link-disabled{
    pointer-events: none;
  }
}







