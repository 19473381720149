.default-section {
  margin: 0;
}
@mixin default-section() {
  position: relative;
  //margin: 0;

  .section__bg {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
  }

  .section__content {
    position: relative;
    z-index: 3;
  }
}




@media (max-width: $smartphone) {

}

.button-link {
  @include font-mono($font-size-large, 1);
  @include button-link();
  text-transform: uppercase;
}


.default-p {
  @include font-sans($font-size-base);
  color: $assertive;
}
@mixin default-p() {
  @include font-sans($font-size-base);
  color: $assertive;
}