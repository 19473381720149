.main-sidemenu__toggle {
  @include z-index($z-index-header+2);
  display: none;
  padding: 29px 24px;
  width: 72px;
  height: 80px;
  background: $transparent;

  span {
    position: absolute;
    display: block;
    width: 24px;
    height: 2px;
    background: $black;
    transform-origin: 50%;

    &:nth-of-type(1) {  top: 0 + 29px  }
    &:nth-of-type(2) {  top: 10 + 29px  }
    &:nth-of-type(3) {  top: 20 + 29px  }
  }

  &.__close {
    span {
      &:nth-of-type(1) {
        top: 10 + 29px;
        transform: rotate(45deg);
      }
      &:nth-of-type(2) {
        display: none;
      }
      &:nth-of-type(3) {
        top: 10 + 29px;
        transform: rotate(135deg);
      }
    }
  }
}

@media (max-width: $menuHideSize) {
  .main-sidemenu__toggle {
    position: absolute;
    right: 0;
    top: 0;
    display: block;
  }
}

@media (max-width: $smartphone) {
  .main-sidemenu__toggle {
    padding: 23px 24px;
    height: 74px;

    span {
      &:nth-of-type(1) {  top: 0 + 23px  }
      &:nth-of-type(2) {  top: 10 + 23px  }
      &:nth-of-type(3) {  top: 20 + 23px  }
    }

    &.__close {
      span {
        &:nth-of-type(1) {
          top: 10 + 23px;
        }
        &:nth-of-type(3) {
          top: 10 + 23px;
        }
      }
    }
  }
}
