.main-header {
  @include z-index($z-index-header);
  position: fixed;
  height: 92px;
  top:0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.__showBG {
    background: $positive;
    border-bottom: 3px solid $assertive;
  }
}

.main-header__bg {
  @include z-index($z-index-header-bg);
  position: fixed;
  width: 100%;
  height: 92px;
  left:0;
  background: $positive;
  border-bottom: 3px solid $assertive;
}

.main-header__title {
  font-size: 48px;
  width: 48px;
  margin: 0;
  color: $white;
}
.__bg {
  .main-header__title {
    color: $assertive;
  }
}

.main-header__budget {
  position: relative;
  height: 36px;
  outline: 0;
  border: 0;
  @include font-sans-bold(14px, 1);
  background: $assertive;
  color: $white;
  padding: 0 10px;

  .arrow-right {
    position: absolute;
    top: 7px;
    left: -75px;
    width: 60px;
    stroke: 4;
  }

  &.hovered {
    color: $assertive;
    .arrow-right {
      display: none;
    }
    &:after {
      color: $white;
      @include pseudo-element-absolute();
      content: 'pide tu presupuesto';
      font-size: 32px;
      top:50%;
      left:50%;
      @include transform-3D(-50%,-50%,10px);
    }
  }
}

.main-header__contact {
  display: none;
  a {
    outline: 0;
    text-decoration: none;
    color: $white;
    font-size: 32px;
    margin-left: 0;
  }
}

@media (max-width: $smartphone) {
  .main-header {
    height: 60px;
    background: $assertive;
    padding: 0 10px;

    &.__showBG {
      background: $assertive;
      border-bottom: 0;
    }
  }

  .__bg {
    .main-header__title {
      color: $white;
    }
  }

  .main-header__title {
    font-size: 38px;
    width: 48px;
  }

  .main-header__budget {
    display: none;
  }

  .main-header__contact {
    display: block;
  }
}