@mixin defaul-arrow() {
  position: relative;

  svg {
    path, line {
      vector-effect: non-scaling-stroke;
    }
  }

  .arrow-line {
    width: 100%;
    height: 100%;
    stroke-width: 3;
  }

  .arrow-head {
    position: absolute;
    stroke-width: 3;
  }
}

.arrow-down {
  @include defaul-arrow();
  width: 23px;
  height: 100px;

  .arrow-line {
    //stroke: $assertive;
  }

  .arrow-head {
    left: 0;
    bottom: -23px;
    //stroke: $assertive;
  }
}

.arrow-up {
  @include defaul-arrow();
  width: 23px;
  height: 100px;
  
  .arrow-line {
    //stroke: $assertive;
  }

  .arrow-head {
    left: 0;
    top: -23px;
    //stroke: $assertive;
  }

}

.arrow-right {
  @include defaul-arrow();
  width: 100px;
  height: 23px;

  .arrow-line {
    stroke: $assertive;
  }

  .arrow-head {
    width: 46px;
    height: auto;
    right: -23px;
    top: 0;
    stroke: $assertive;
  }
}


.arrow-left {
  @include defaul-arrow();
  width: 100px;
  height: 23px;

  .arrow-line {
    stroke: $assertive;
  }

  .arrow-head {
    width: 46px;
    height: auto;
    left: -23px;
    top: 0;
    stroke: $assertive;
  }
}

.arrow-close {
  @include defaul-arrow();
  margin: 0 auto;
  width: 24px;
  height: 23px;
  stroke-width: 3;
  fill: transparent;
}