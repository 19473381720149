menu-link,
%menu-link {
  position: relative;
  cursor: pointer;
  display: inline-block;
  outline: 0;
  text-decoration: none;

  &:before {
    position: absolute;
    display: none;
    content: '';

    bottom: 0px;
    left:0px;

    width: 100%;
    height: 2px;
  }



  &.__linkActive {
    pointer-events: none;
    color:$black;
    &:before {
      display: none !important;
    }
    &:after {
      @include pseudo-element-absolute();
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: $dark;
      left: 50%;
      margin-left: -4px;
      top: 32px;
    }
  }
}

.__cursor {
  .menu-link,
  %menu-link {
    &:hover {
      text-decoration: none;

      &:before {
        display: block;
      }
    }
  }
}

@mixin menu-link($color:$black, $barColor:$black, $barHeight:2px) {
  @extend %menu-link;
  color:$color;
  &:before {
    background-color: $barColor;
    height: $barHeight;
    bottom: -0.2em;
  }
}