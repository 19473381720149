
@mixin underline-link($color:$black, $hover:$black, $barColor:$transparent, $hoverBar:$black, $barHeight:1px) {
  position: relative;
  outline: 0;
  text-decoration: none;
  color: $color;

  border-bottom: $barHeight solid $barColor;
  transition: color .4s cubic-bezier(0.165, 0.840, 0.440, 1.000);

  &:after {
    position: absolute;
    content: ' ';
    bottom: -$barHeight;
    right:0px;
    border-bottom: $barHeight solid $hoverBar;
    width: 0;
    transition: all .8s cubic-bezier(0.165, 0.840, 0.440, 1.000);
  }

  &:hover {
    color: $hover;
    transition: color .4s cubic-bezier(0.165, 0.840, 0.440, 1.000);

    &:after {
      width: 100%;
      transition: all .4s cubic-bezier(0.165, 0.840, 0.440, 1.000);
    }
  }

  &.link-active {
    &:after {
      width: 100%;
    }
  }
}





