$s:24px;

.default-form {

  display: flex;
  flex-wrap: wrap;
  font-size: $font-size-base;
  color: $white;

  .col {
    width: 50%;

    &:nth-child(2) {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      padding: 0 0 0 40px;
    }
  }

  .input-holder {
    position: relative;
    width: 100%;
    background: transparent;
    border-bottom: 3px solid $white;
    margin: 0 0 1em;

    &.error {
      border-color: red;
      input,textarea {
        color: red;
        &::placeholder {
          color: red;
        }
      }
    }

    label {
      position: absolute;
      top: 0.2em;
      left:0;
      transform-origin: 0 100%;
      transition: all .2s ease;
    }
    &.notvoid,
    &.focus {
      label {
        top: -1em;
        transform: scale(0.7, 0.7);
      }
    }
  }



  input,textarea {
    background: $transparent;
    outline: 0;
    border: 0 solid transparent;
    border-radius: 0;
    width: 100%;
    height: auto;
    padding: 0.2em 0;
    transition: all .4s cubic-bezier(0.165, 0.840, 0.440, 1.000);

    &::placeholder {
      opacity: 1;
      color: $white;
      text-transform: uppercase;
    }
  }

  button {
    @include font-sans($font-size-base);
    @include button-link($white, $assertive, $transparent, $white, $white, $white);
    border-width: 3px;
    width: 250px;
    &:after, &:before {
      display: none;
    }

    &.__loading {
      color: $transparent;
      &:before {
        content: 'ENVIANDO...';
        color: $white;
        text-align: center;
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding-top: 0.6em;
        background: $assertive;
      }
    }
  }
}

.check-holder {
  margin-top: 10px;

  &:before {
    content: '';
    position: absolute;
    margin: 0;
    width: 30px;
    height: 30px;
    border: 3px solid $white;
    background: $transparent;
  }

  &.error {
    &:before {
      border-color: red;
    }
  }

  label {
    position: relative;
    display: block;
    cursor: pointer;
    padding: 8px $padding-sizes 8px 40px;
    @include font-sans($font-size-small);

    a {
      color: $white;
    }

    &:after {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      position: absolute;
      top: 10px;
      left: 10px;
      background: $white;
      border-radius: 5px;
      opacity: 0;
      transition: all 0.4s ease;
    }
  }

  input[type=checkbox] {
    position: absolute;
    visibility: hidden;
    &:checked + label:after {
      background: $white;
      opacity: 1;
    }
  }
}



@media (max-width: $tabletPortrait) {
  .default-form {
    position: relative;
    &:before {
      @include pseudo-element-absolute();
      top:-10%;
      left:-50%;
      width: 200%;
      height: 120%;
      background: $grad-color;
    }

    button {
      margin-top: 32px;
    }

    .col {
      flex: 1 0 100%;
      width: 100%;

      &:last-of-type {
        padding: 0;

        button {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: $smartphone) {
  .default-form {
    button {
      width: 100%;
    }
  }
}